import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

export default function About() {
  return (
    <Layout>
      <SEO title="A Propos" />
      <section className="contact-page">
        <article className="text-center">
          <h2 className="text-2xl">Page en construction</h2>
        </article>
        <p>En attendant prenez un peu de temps pour vous relaxer et respirer</p>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a
              href=" https://relaxer-cedcreate.surge.sh/"
              class="bg-transparent hover:bg-white-500 text-white-700 font-semibold hover:text-yellow py-2 px-4 border border-white-500 hover:border-transparent rounded"
              target="_blank"
              rel="noopener noreferrer"
            >
              Se relaxer
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}
